'use strict';
import $ from 'jquery';
import 'remodal';
import mask from 'jquery.maskedinput';

export default class Modal {
  constructor() {
    this.name = 'modal';
    if ($('#qphone').length){
        $("#qphone").mask("+7 (999) 999-99-99", {
          completed: function(){ 
            
           }
        });
      }
    if ($('#mphone').length){
      $("#mphone").mask("+7 (999) 999-99-99", {
        completed: function(){ 

         }
      });
    }
  }
}