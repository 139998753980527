'use strict';

import $ from 'jquery';
import 'slick-carousel';

export default class General{
  constructor() {
    this.name = 'general';
    if($('#js-slider').length){
      $('#js-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
      });
    }
  }
}