// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

import $ from 'jquery';


import General from './general';
import Header from '../_modules/header/header';
import Modal from '../_modules/modal/modal';

$(() => {
  
  new General();
  new Header();
  new Modal();
});
